<script setup lang="ts">
interface Props {
  name?: string
  modelValue: string | number
  hint?: string
  placeholder?: string
  description?: string
  white?: boolean
  disabled?: boolean
  readonly?: boolean
  options: { name: string, value: string|number }[]
}

const props = withDefaults(defineProps<Props>(), {
  name: '',
  hint: '',
  placeholder: '',
  description: '',
  white: false,
  disabled: false,
  readonly: false
})
defineEmits(['update:modelValue'])

const value = defineModel<string|number>()

onMounted(() => {
  value.value = props.modelValue
})
</script>

<template>
  <label class="input">
    <span v-if="name" class="input__name">{{ name }}</span>
    <span class="input__field-wrapper" :class="{ white, disabled }">
      <select
        v-model="value"
        class="input__field"
        :class="{
          'input__field--select-empty': !value,
        }"
      >
        <option v-if="placeholder" value="">{{ placeholder }}</option>
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
        >
          {{ option.name }}
        </option>
      </select>
    </span>
  </label>
</template>

<style scoped lang="scss">
@use '@/assets/scss/components/input';
</style>