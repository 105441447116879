<script setup lang="ts">
import useModalsStore from '~/stores/modals'

const router = useRouter()
const localePath = useLocalePath()

const modalsStore = useModalsStore()
const config = useRuntimeConfig()

const form = reactive({
  name: '',
  phone: '',
  complex_id: '',
  terms: false,
})

const loading = ref(false)
const isSubmitDisabled = computed(() => {
  return !form.name || !form.complex_id || !form.phone || !form.terms || loading.value
})

function clearForm() {
  form.name = ''
  form.phone = ''
  form.complex_id = ''
  form.terms = false

  modalsStore.contactModalAdditionalData = {}
}

const { data: complexes, pending: complexesPending } = await useFetch<any>(`${config.public.apiUrl}/complexes`)

const complexesOptions = computed(() => {
  return complexes.value?.body?.map((complex: any) => ({
    name: complex.complex_name,
    value: complex.id
  })) || []
})

function onSubmit() {
  loading.value = true

  useFetch(`${config.public.apiUrl}/clients`, {
    method: 'POST',
    body: JSON.stringify({
      first_name: form.name,
      phone: form.phone,
      complex_id: form.complex_id,
      source: 'call_back',
      website: 'estateinvest.md',
      ...modalsStore.contactModalAdditionalData
    }),
    onResponse (response) {
      modalsStore.contactModalAdditionalData = {}
      clearForm()
      modalsStore.close('contact')
      if (response.response.status === 201) {
        const routeData = router.resolve(localePath('/success'))
        window.open(routeData.href, '_blank')
      }
      loading.value = false
    }
  })
}
</script>

<template>
  <ModalWindow name="contact" @cancel="clearForm">
    <div class="contact-modal">
      <div class="contact-modal__header">
        <h3 class="contact-modal__title">{{ $t('contactModal.title') }}</h3>
        <button class="contact-modal__close-btn" @click="modalsStore.close('contact')">
          <SvgIcon name="close" />
        </button>
      </div>
      <form class="contact-modal__inputs" @submit.prevent="onSubmit">
        <InputField v-model="form.name" white :placeholder="$t('contactModal.firstName')" />
        <InputField v-model="form.phone" white :placeholder="$t('contactModal.phoneNumber')" />
        <SelectInput
          v-if="!complexesPending"
          v-model="form.complex_id"
          white
          :placeholder="$t('contactModal.chooseComplex')"
          :options="complexesOptions"
        />
        <button
          :disabled="isSubmitDisabled"
          type="submit"
          class="btn btn--primary btn--large"
        >
          <RectangleLoader v-if="loading" color="currentColor" size="22" />
          <template v-else>{{ $t('contactModal.button') }}</template>
        </button>
        <CheckboxInput v-model="form.terms">
          {{ $t('contactModal.iAgreeTo') }}
          <NuxtLinkLocale
            to="/policy"
            @click="modalsStore.close('contact')"
          >
            {{ $t('contactModal.termsAndConditions') }}
          </NuxtLinkLocale>
        </CheckboxInput>
      </form>
    </div>
  </ModalWindow>
</template>

<style scoped lang="scss">
@use '@/assets/scss/components/contact-modal';
</style>