import simpleParallax from 'simple-parallax-js'
import { defineNuxtPlugin } from 'nuxt/app'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('parallax', {
    mounted(el, binding) {
      const options = binding.value || { delay: .5 }
      new simpleParallax(el, options)

      nextTick(() => {
        const $divider = el.closest('.parallax-divider')
        document.querySelectorAll('.parallax-divider').forEach(($d, index) => {
          if ($d === $divider) {
            ($d as HTMLElement).style.setProperty('--_parallax-index', (index as unknown as string))
          }
        })
      })
    },
  })
})