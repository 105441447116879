<script setup lang="ts">
interface Props {
  type?: 'text' | 'number' | 'email' | 'password'
  name?: string
  placeholder?: string
  modelValue: string | number
  hint?: string
  rightText?: string
  description?: string
  white?: boolean
  disabled?: boolean
  readonly?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  name: '',
  type: 'text',
  placeholder: '',
  hint: '',
  rightText: '',
  description: '',
  white: false,
  disabled: false,
  readonly: false
})
defineEmits(['update:modelValue'])

const value = defineModel<string|number>()

onMounted(() => {
  value.value = props.modelValue
})
</script>

<template>
  <label class="input">
    <span v-if="name" class="input__name">{{ name }}</span>
    <span class="input__field-wrapper" :class="{ white, disabled }">
      <input
        v-model="value"
        :type="type"
        class="input__field"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
      />
      <span v-if="hint" class="input__hint">
        <SvgIcon name="info" class="input__hint-trigger" />
        <span class="input__hint-content">{{ hint }}</span>
      </span>
      <span v-if="rightText" class="input__right-text">{{ rightText }}</span>
    </span>
    <span v-if="description" class="input__description">{{ description }}</span>
  </label>
</template>

<style scoped lang="scss">
@use '@/assets/scss/components/input';
</style>