import { default as aboutlv6i1FL0CfMeta } from "/opt/build/repo/pages/about.vue?macro=true";
import { default as contactsQ2Ks7vPefgMeta } from "/opt/build/repo/pages/contacts.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as policyfKFIeUXL10Meta } from "/opt/build/repo/pages/policy.vue?macro=true";
import { default as _91id_939LOMfExrirMeta } from "/opt/build/repo/pages/projects/[id].vue?macro=true";
import { default as indexxtMqaql9AYMeta } from "/opt/build/repo/pages/projects/index.vue?macro=true";
import { default as success5PxaWcwlslMeta } from "/opt/build/repo/pages/success.vue?macro=true";
import { default as terms9FLT7o4CI4Meta } from "/opt/build/repo/pages/terms.vue?macro=true";
export default [
  {
    name: aboutlv6i1FL0CfMeta?.name ?? "about___en",
    path: aboutlv6i1FL0CfMeta?.path ?? "/en/about",
    meta: aboutlv6i1FL0CfMeta || {},
    alias: aboutlv6i1FL0CfMeta?.alias || [],
    redirect: aboutlv6i1FL0CfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutlv6i1FL0CfMeta?.name ?? "about___ro",
    path: aboutlv6i1FL0CfMeta?.path ?? "/ro/about",
    meta: aboutlv6i1FL0CfMeta || {},
    alias: aboutlv6i1FL0CfMeta?.alias || [],
    redirect: aboutlv6i1FL0CfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutlv6i1FL0CfMeta?.name ?? "about___ru",
    path: aboutlv6i1FL0CfMeta?.path ?? "/ru/about",
    meta: aboutlv6i1FL0CfMeta || {},
    alias: aboutlv6i1FL0CfMeta?.alias || [],
    redirect: aboutlv6i1FL0CfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: contactsQ2Ks7vPefgMeta?.name ?? "contacts___en",
    path: contactsQ2Ks7vPefgMeta?.path ?? "/en/contacts",
    meta: contactsQ2Ks7vPefgMeta || {},
    alias: contactsQ2Ks7vPefgMeta?.alias || [],
    redirect: contactsQ2Ks7vPefgMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsQ2Ks7vPefgMeta?.name ?? "contacts___ro",
    path: contactsQ2Ks7vPefgMeta?.path ?? "/ro/contacts",
    meta: contactsQ2Ks7vPefgMeta || {},
    alias: contactsQ2Ks7vPefgMeta?.alias || [],
    redirect: contactsQ2Ks7vPefgMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsQ2Ks7vPefgMeta?.name ?? "contacts___ru",
    path: contactsQ2Ks7vPefgMeta?.path ?? "/ru/contacts",
    meta: contactsQ2Ks7vPefgMeta || {},
    alias: contactsQ2Ks7vPefgMeta?.alias || [],
    redirect: contactsQ2Ks7vPefgMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___en",
    path: indexlaFeZuYZkUMeta?.path ?? "/en",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___ro",
    path: indexlaFeZuYZkUMeta?.path ?? "/ro",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___ru",
    path: indexlaFeZuYZkUMeta?.path ?? "/ru",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: policyfKFIeUXL10Meta?.name ?? "policy___en",
    path: policyfKFIeUXL10Meta?.path ?? "/en/policy",
    meta: policyfKFIeUXL10Meta || {},
    alias: policyfKFIeUXL10Meta?.alias || [],
    redirect: policyfKFIeUXL10Meta?.redirect,
    component: () => import("/opt/build/repo/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: policyfKFIeUXL10Meta?.name ?? "policy___ro",
    path: policyfKFIeUXL10Meta?.path ?? "/ro/policy",
    meta: policyfKFIeUXL10Meta || {},
    alias: policyfKFIeUXL10Meta?.alias || [],
    redirect: policyfKFIeUXL10Meta?.redirect,
    component: () => import("/opt/build/repo/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: policyfKFIeUXL10Meta?.name ?? "policy___ru",
    path: policyfKFIeUXL10Meta?.path ?? "/ru/policy",
    meta: policyfKFIeUXL10Meta || {},
    alias: policyfKFIeUXL10Meta?.alias || [],
    redirect: policyfKFIeUXL10Meta?.redirect,
    component: () => import("/opt/build/repo/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: _91id_939LOMfExrirMeta?.name ?? "projects-id___en",
    path: _91id_939LOMfExrirMeta?.path ?? "/en/projects/:id()",
    meta: _91id_939LOMfExrirMeta || {},
    alias: _91id_939LOMfExrirMeta?.alias || [],
    redirect: _91id_939LOMfExrirMeta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_939LOMfExrirMeta?.name ?? "projects-id___ro",
    path: _91id_939LOMfExrirMeta?.path ?? "/ro/projects/:id()",
    meta: _91id_939LOMfExrirMeta || {},
    alias: _91id_939LOMfExrirMeta?.alias || [],
    redirect: _91id_939LOMfExrirMeta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_939LOMfExrirMeta?.name ?? "projects-id___ru",
    path: _91id_939LOMfExrirMeta?.path ?? "/ru/projects/:id()",
    meta: _91id_939LOMfExrirMeta || {},
    alias: _91id_939LOMfExrirMeta?.alias || [],
    redirect: _91id_939LOMfExrirMeta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/[id].vue").then(m => m.default || m)
  },
  {
    name: indexxtMqaql9AYMeta?.name ?? "projects___en",
    path: indexxtMqaql9AYMeta?.path ?? "/en/projects",
    meta: indexxtMqaql9AYMeta || {},
    alias: indexxtMqaql9AYMeta?.alias || [],
    redirect: indexxtMqaql9AYMeta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: indexxtMqaql9AYMeta?.name ?? "projects___ro",
    path: indexxtMqaql9AYMeta?.path ?? "/ro/projects",
    meta: indexxtMqaql9AYMeta || {},
    alias: indexxtMqaql9AYMeta?.alias || [],
    redirect: indexxtMqaql9AYMeta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: indexxtMqaql9AYMeta?.name ?? "projects___ru",
    path: indexxtMqaql9AYMeta?.path ?? "/ru/projects",
    meta: indexxtMqaql9AYMeta || {},
    alias: indexxtMqaql9AYMeta?.alias || [],
    redirect: indexxtMqaql9AYMeta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: success5PxaWcwlslMeta?.name ?? "success___en",
    path: success5PxaWcwlslMeta?.path ?? "/en/success",
    meta: success5PxaWcwlslMeta || {},
    alias: success5PxaWcwlslMeta?.alias || [],
    redirect: success5PxaWcwlslMeta?.redirect,
    component: () => import("/opt/build/repo/pages/success.vue").then(m => m.default || m)
  },
  {
    name: success5PxaWcwlslMeta?.name ?? "success___ro",
    path: success5PxaWcwlslMeta?.path ?? "/ro/success",
    meta: success5PxaWcwlslMeta || {},
    alias: success5PxaWcwlslMeta?.alias || [],
    redirect: success5PxaWcwlslMeta?.redirect,
    component: () => import("/opt/build/repo/pages/success.vue").then(m => m.default || m)
  },
  {
    name: success5PxaWcwlslMeta?.name ?? "success___ru",
    path: success5PxaWcwlslMeta?.path ?? "/ru/success",
    meta: success5PxaWcwlslMeta || {},
    alias: success5PxaWcwlslMeta?.alias || [],
    redirect: success5PxaWcwlslMeta?.redirect,
    component: () => import("/opt/build/repo/pages/success.vue").then(m => m.default || m)
  },
  {
    name: terms9FLT7o4CI4Meta?.name ?? "terms___en",
    path: terms9FLT7o4CI4Meta?.path ?? "/en/terms",
    meta: terms9FLT7o4CI4Meta || {},
    alias: terms9FLT7o4CI4Meta?.alias || [],
    redirect: terms9FLT7o4CI4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: terms9FLT7o4CI4Meta?.name ?? "terms___ro",
    path: terms9FLT7o4CI4Meta?.path ?? "/ro/terms",
    meta: terms9FLT7o4CI4Meta || {},
    alias: terms9FLT7o4CI4Meta?.alias || [],
    redirect: terms9FLT7o4CI4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: terms9FLT7o4CI4Meta?.name ?? "terms___ru",
    path: terms9FLT7o4CI4Meta?.path ?? "/ru/terms",
    meta: terms9FLT7o4CI4Meta || {},
    alias: terms9FLT7o4CI4Meta?.alias || [],
    redirect: terms9FLT7o4CI4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms.vue").then(m => m.default || m)
  }
]