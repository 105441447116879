interface ContactModalAdditionalData {
  source: 'call_back' | 'credit' | 'apartment'
  price?: number | string
  period?: number | string
  amount_of_money?: number | string
  apartment_id?: number | string
}

const useModalsStore = defineStore({
  id: 'modals',
  state: () => ({
    active: <(string | number)[]>[],
    contactModalAdditionalData: {}
  }),
  getters: {
    isSomeActive: (state) => state.active.length > 0
  },
  actions: {
    open(modalId: string | number) {
      this.active.push(modalId)
    },
    close(modalId: string | number | null) {
      this.active = modalId ? this.active.filter(id => id !== modalId) : []
    },
    toggle(modalId: string | number) {
      if (this.active.includes(modalId)) {
        this.close(modalId)
      } else {
        this.open(modalId)
      }
    },
    openContactModal(data: ContactModalAdditionalData | null = null) {
      if (data) {
        this.contactModalAdditionalData = data
      }

      this.open('contact')
    }
  }
})

export default useModalsStore