export default defineI18nLocale(async () => {
    return {
        nav: {
            home: 'Acasă',
            about: 'Despre companie',
            projects: 'Proiecte',
            contacts: 'Contacte',
        },
        requestConsultation: 'Solicitați o consultație',
        welcome: {
            pretitle: 'Change Your Life',
            title: 'Descoperă complexul\n<strong>{complex}</strong>',
            buttonText: 'Vezi complexul',
            instagramText: 'Urmărește-ne pe Instagram',
        },
        experience: {
            pretitle: 'Încă te mai gândești?',
            title: 'Experiența obținută vorbește pentru noi',
            years: 'ani',
            buildings: 'clădiri',
            clients: 'clienți',
            experience: 'experiență',
            built: 'construiți',
            developed: 'dezvoltați',
            remainedSatisfied: 'care ne-au mulțumit',
        },
        all: 'Toate',
        actual: 'În construcție',
        finished: 'Finisat',
        infoTitles: {
            blocks: 'Blocuri',
            apartments: 'Apartamente',
            undergroundParking: 'Parcări subterane',
            landParking: 'Parcări terestre',
            available: 'Mai sunt disponibile',
            availableApartments: 'apartamente',
        },
        functions: {
            pretitle: 'Cum funcționează',
            title: 'Cu 4 pași mai aproape de casa ta',
            step1: 'Solictă o consultație direct pe site',
            step2: 'Reprezentantul Estate te va contacta',
            step3: 'Vino la noi în birou pentru a afla toate disponibilităție și graficul de achitare',
            step4: 'Alege apartamentul perfect pentru tine',
        },
        advantages: {
            pretitle: 'Pentru ca să îți facem alegerea mai ușoară',
            title: 'Partenerii noștri te ajută cu…',
            item1: {
                title: 'Reparația la cheie a apartamentului',
                text: 'Furnizarea completă a serviciilor de renovare și amenajare, inclusiv materiale, muncă și coordonare, pentru a asigura finalizarea proiectului fără implicarea proprietarului.'
            },
            item2: {
                title: 'Design individual',
                text: 'Personalizarea spațiului pentru a reflecta gusturile, nevoile și personalitatea proprietarului, creând un mediu unic și confortabil, adaptat exclusiv pentru el sau familia sa.'
            },
            item3: {
                title: 'Soluții smart pentru o casă inteligentă',
                text: 'Tehnologii avansate precum sisteme de automatizare a iluminatului, securitate integrată, control vocal al dispozitivelor, termostate inteligente și senzori de monitorizare a consumului de energie, toate pentru a crea un mediu confortabil, sigur și eficient energetic.'
            },
            item4: {
                title: 'Instituții financiare și bănci',
                text: 'Dacă nu ai reușit achiziționarea unui apartament în rate direct de la dezvoltator, atunci imediat după darea în exploatare te putem ajuta să identifici oferta potrivită pentru Ipotecă sau Programul Guvernamental Prima Casă.'
            },
            buttonText: 'Beneficiază acum de ofertă'
        },
        footer: {
            cta: {
                title: 'Ai făcut deja alegerea?',
                buttonText: 'Contactează-ne',
                text: 'Estate Invest Company este o companie de constructii şi dezvoltare imobiliare cu sediul în Chișinău, fiind unul din cei mai pragmatici antreprenori generali din Moldova.'
            },
            links: {
                complexes: {
                    title: 'Complexe'
                },
                about: {
                    title: 'Despre',
                    items: {
                        company: 'Despre companie',
                        finishedProjects: 'Proiecte finisate',
                        inProgressProjects: 'Proiecte în dezvoltare',
                        contacts: 'Contacte',
                    }
                },
                office: {
                    title: 'Oficiul de vânzări',
                }
            },
            socialsTitle: 'Urmărește-ne pe rețelele de socializare',
            copyright: '© 2012 Estate Invest Company - Toate drepturile sunt rezervate.',
            policies: {
                cookies: 'Politica de cookie-uri',
                privacy: 'Politica de confidențialitate'
            },
            developedBy: 'realizat de echipa'
        },

        about: {
            title: 'Despre noi',
            text: 'Estate Invest Company a fost fondată în anul 2015 cu un scop clar: de a deveni un lider în domeniul dezvoltării imobiliare din Republica Moldova. Ne-am angajat să oferim o abordare inovatoare și de înaltă calitate în construcția de locuințe și spații comerciale, cu accentul pus pe arhitectură originală dar și confortul și satisfacția clienților. Primele proiecte inaugurate, precum Park House și Estate Tower, au reprezentat repere în industrie datorită calității superioare a construcțiilor și atenției la detalii. Aceste proiecte ne-au ajutat să ne consolidăm reputația ca un dezvoltator imobiliar de încredere și respectat în societate.',
            weOffer: {
                title: 'Suntem cei mai buni deoarece oferim',
                items: {
                    item1: {
                        title: 'Garantăm calitate superioară',
                        text: 'Prioritizăm calitatea în construcții și atenția la detalii pentru a spori confortul fiecărui locatar care alege Estate Invest Company.'
                    },
                    item2: {
                        title: 'Construcții cu caracter',
                        text: 'Ne angajăm să îmbunătățim calitatea vieții comunității prin proiecte valoroase. Ne concentrăm pe identificarea și dezvoltarea de proiecte în cele mai bune și promițătoare locații.'
                    },
                    item3: {
                        title: 'Inovație și excelență',
                        text: 'Integrăm cele mai recente tehnologii și inovații în toate proiectele noastre. Energoeficiența, durabilitatea și integrarea sistemelor smart reprezintă doar câteva puncte esențiale în dezvoltarea fiecărui obiect.'
                    },
                    item4: {
                        title: 'Angajament față de clienți',
                        text: 'Fiecare proiect este realizat cu dedicare și atenție la nevoile clienților, fie aceștia cumpărători finali, familii tinere sau investitori.'
                    },
                }
            },
            team: {
                title: 'O echipă mare ce construiește visuri mărețe',
                text: 'Cu o echipă de peste 200 de angajați talentați și dedicați, compania operează proiecte complexe și de rezonanță națională. Echipa de management, condusă de profesioniști experimentați în industria imobiliară, menține calitatea și viziunea dezvoltatorului imobiliar și are grijă ca toate proiectele să fie livrate cu succes. Profesioniștii de pe șantiere sunt responsabili de implementarea planurilor de construcție, de gestionarea resurselor și de asigurarea calității lucrărilor. Prin muncă asiduă și competență tehnică, aceștia transformă viziunile în realitate. Iar echipa de vânzări prin intermediul abilităților lor de comunicare și cunoștințelor extinse, creează și mențin relații solide cu clienții și partenerii, contribuind la succesul și creșterea afacerii.'
            },
            testimonials: {
                pretitle: 'Parerile clienților contează',
                title: 'Experiența clienților noștri',
                items: {
                    item1: {
                        text: 'Experiența mea cu Estate Invest a fost excepțională! Proiectul Estate Tower este o realizare minunată în domeniul imobiliar. A fost prima mea investiție, iar de curând am mai achiziționat 2 apartamente la Olimp Towers',
                        name: 'Ion Rusu',
                        position: 'Investitor Estate Tower',
                    },
                    item2: {
                        text: 'Calitatea construcțiilor lor este remarcabilă. Complex situat chiar lângă Parcul Valea Trandafirilor - cea mai bună decizie din viața noastră.',
                        name: 'Ana Prodan',
                        position: 'Locuitoare a complexului Vorniceni',
                    },
                }
            },
        },

        complex: {
            description: 'Echipa noastră transformă visuri în realitate, construind nu doar clădiri, ci și povestea fiecărui vis realizat. Alegeți să vă încredințați echipei noastre pentru a modela împreună experiențe de neuitat.',
            photos: {
                pretitle: 'Beneficiile unei terase',
                title: 'Admiră apusurile și răsăriturile cu o cafeluță',
                text: 'Începe-ți ziua cu o priveliște uluitoare, admirând apusurile și răsăriturile în timp ce savurezi o cafeluță caldă. Experimentează liniștea și frumusețea naturii, bucurându-te de momente de contemplare și energizare. Fiecare înghițitură devine o călătorie într-un spațiu al liniștii, înconjurat de frumusețea și seninătatea peisajului.'
            },
            map: {
                pretitle: 'Infrastructura',
                title: 'Descoperă împrejurimile casei tale',
                pharmacy: 'Farmacie',
                shop: 'Magazin',
                restaurant: 'Restaurant',
                playground: 'Teren de joacă',
            },
            progress: {
                pretitle: 'Progresul construcției',
                title: 'Vezi cum s-a dezvoltat complexul de-a lungul timpului',
                text: 'Descoperă evoluția uimitoare a complexului nostru de-a lungul timpului. De la primele temelii până la arhitectura impresionantă și facilitățile moderne, fiecare etapă reflectă angajamentul nostru pentru excelență și inovație. Priviți înapoi la călătoria noastră și bucurați-vă de transformarea continuă a acestui spațiu într-un mediu vibrant și contemporan.'
            }
        },

        contacts: {
            pretitle: 'Contactele noastre',
            title: 'Oficiul de vânzări',
            address: 'Municipiul Chișinău strada Alecu Russo 15, oficiul 52',
            workingHours: 'Luni până Vineri de la 9.00 - 18.00',
        },

        success: {
            title: 'Datele Dvs. au fost transmise cu succes!',
            description: 'În scurt timp un agent Estate Invest te va contacta'
        },

        contactModal: {
            title: 'Completează câmpurile',
            firstName: 'Numele*',
            lastName: 'Prenumele*',
            phoneNumber: 'Numărul de telefon*',
            email: 'Adresa electronică (opțional)',
            button: 'Trimite datele de contact',
            iAgreeTo: 'Sunt deacord cu',
            termsAndConditions: 'Termenii și condițiile',
            chooseComplex: 'Complexul',
        },

        noData: 'Nu sunt date disponibile',

        seo: {
            index: {
                title: 'Estate Invest Company - Dezvoltator imobiliar de încredere',
                description: 'Estate Invest Company este o companie de constructii şi dezvoltare imobiliare cu sediul în Chișinău, fiind unul din cei mai pragmatici antreprenori generali din Moldova.'
            },
            contact: {
                title: 'Contactează-ne',
                description: 'Municipiul Chișinău strada Alecu Russo 15, oficiul 52. Luni până Vineri de la 9.00 - 18.00'
            },
            about: {
                title: 'Despre noi - Estate Invest Company',
                description: 'Estate Invest Company a fost fondată în anul 2015 cu un scop clar: de a deveni un lider în domeniul dezvoltării imobiliare din Republica Moldova.'
            },
            success: {
                title: 'Datele Dvs. au fost transmise cu succes!',
                description: 'În scurt timp un agent Estate Invest te va contacta'
            },
            projects: {
                title: 'Proiecte - Estate Invest Company',
                description: 'Estate Invest Company este un dezvoltator imobiliar de încredere, cu o experiență vastă în construcția de complexuri rezidențiale și comerciale.'
            }
        },

        plans: {
            label: 'Planimetriile disponibile',
            title: 'Studiază planimetriile disponibile și alegeți planimetria potrivită pentru tine',
            description: 'Descoperă planimetriile disponibile și alegeți apartamentul perfect pentru tine. Fiecare planimetrie este proiectată pentru a oferi confort, funcționalitate și spațiu optim, reflectând nevoile și preferințele tale. Alegeți planimetria care vă reprezintă cel mai bine și începeți să vă transformați visul în realitate.'
        }
    }
})