export default defineI18nLocale(async () => {
    return {
        nav: {
            home: 'Home',
            about: 'About the company',
            projects: 'Projects',
            contacts: 'Contacts',
        },
        requestConsultation: 'Request a consultation',
        welcome: {
            pretitle: 'Change Your Life',
            title: 'Discover the complex\n<strong>{complex}</strong>',
            buttonText: 'See complex',
            instagramText: 'Follow us on Instagram',
        },
        experience: {
            pretitle: 'You\'re still thinking?',
            title: 'The experience we gained speaks for us',
            years: 'years',
            buildings: 'buildings',
            clients: 'customers',
            experience: 'of experience',
            built: 'built',
            developed: 'developed',
            remainedSatisfied: 'remained satisfied',
        },
        all: 'All',
        actual: 'On development',
        finished: 'Developed',
        infoTitles: {
            blocks: 'Blocks',
            apartments: 'Apartments',
            undergroundParking: 'Underground parkings',
            landParking: 'Ground parking',
            available: 'Still available',
            availableApartments: 'apartments',
        },
        functions: {
            pretitle: 'How it works',
            title: '4 steps closer to your home',
            step1: 'Request a consultation directly on the website',
            step2: 'The Estate representative will contact you',
            step3: 'Come to our office to find out all the availability and the payment schedule',
            step4: 'Choose the perfect apartment for you',
        },
        advantages: {
            pretitle: 'To make your choice easier',
            title: 'Our partners help you with…',
            item1: {
                title: 'Turnkey repair of the apartment',
                text: 'Complete provision of renovation and fit-out services, including materials, labor and coordination, to ensure project completion without owner involvement.'
            },
            item2: {
                title: 'Individual design',
                text: 'Customizing the space to reflect the owner\'s tastes, needs and personality, creating a unique and comfortable environment tailored exclusively for them or their family.'
            },
            item3: {
                title: 'Smart solutions for a smart home',
                text: 'Advanced technologies such as lighting automation systems, integrated security, voice control of devices, smart thermostats and energy monitoring sensors, all to create a comfortable, safe and energy efficient environment.'
            },
            item4: {
                title: 'Financial institutions and banks',
                text: 'If you did not succeed in purchasing an apartment in installments directly from the developer, then immediately after commissioning we can help you identify the right offer for the Mortgage or the First Home Government Program (Prima Casă).'
            },
            buttonText: 'Take the offer now'
        },
        footer: {
            cta: {
                title: 'Have you already made your choice?',
                buttonText: 'Contact us',
                text: 'Estate Invest Company is a real estate construction and development company based in Chișinău, known as one of the most pragmatic general contractors in Moldova.',
            },
            links: {
                complexes: {
                    title: 'Complexes'
                },
                about: {
                    title: 'About',
                    items: {
                        company: 'About the company',
                        finishedProjects: 'Developed projects',
                        inProgressProjects: 'Developing projects',
                        contacts: 'Contact us',
                    }
                },
                office: {
                    title: 'Sales office',
                }
            },
            socialsTitle: 'Follow us on social media',
            copyright: '© 2012 Estate Invest Company - All rights reserved',
            policies: {
                cookies: 'Cookie Policy',
                privacy: 'Privacy Policy',
            },
            developedBy: 'created by the team',
        },

        about: {
            title: 'About us',
            text: `
        Estate Invest Company was founded in 2015 with a clear goal: to become a leader in the field of real estate development in the Republic of Moldova. We are committed to providing an innovative and high-quality approach to the construction of homes and commercial spaces, with an emphasis on original architecture, but also comfort and customer satisfaction.
        <br/><br/>
        The first inaugurated projects, such as Park House and Estate Tower, were industry benchmarks due to their superior construction quality and attention to detail. These projects have helped us to strengthen our reputation as a reliable and respected real estate developer in the society.
      `,
            weOffer: {
                title: 'We are the best because we offer',
                items: {
                    item1: {
                        title: 'We guarantee superior quality',
                        text: 'We prioritize quality in construction and attention to detail to increase the comfort of every tenant who chooses Estate Invest Company.',
                    },
                    item2: {
                        title: 'Constructions with character',
                        text: 'We are committed to improving the quality of community life through valuable projects. We focus on identifying and developing projects in the best and most promising locations.',
                    },
                    item3: {
                        title: 'Innovation and excellence',
                        text: 'We integrate the latest technologies and innovations in all our projects. Energy efficiency, durability and the integration of smart systems are just a few essential points in the development of each object.',
                    },
                    item4: {
                        title: 'Commitment to customers',
                        text: 'Each project is carried out with dedication and attention to the needs of the clients, either they are the end buyers, young families or investors.',
                    },
                }
            },
            team: {
                title: 'A big team building big dreams',
                text: 'With a team of over 200 talented and dedicated employees, the company operates complex projects with national resonance. The management team, led by experienced real estate professionals, maintains the quality and vision of the real estate developer and ensures that all projects are delivered successfully. Site professionals are responsible for implementing construction plans, managing resources and ensuring the quality of work. Through hard work and technical expertise, they turn visions into reality. And the sales team, through their communication skills and extensive knowledge, create and maintain strong relationships with customers and partners, contributing to the success and growth of the business.',
            },
            testimonials: {
                pretitle: 'Parerile clienților contează',
                title: 'Our customers\' experience',
                items: {
                    item1: {
                        text: 'My experience with Estate Invest was exceptional! The Estate Tower project is a wonderful achievement in the real estate field. It was my first investment, and I recently purchased 2 more apartments at Olimp Towers',
                        name: 'Ion Rusu',
                        position: 'Estate Tower Investor',
                    },
                    item2: {
                        text: 'The quality of their constructions is remarkable. Complex located right next to Valea Trandafirilor Park - the best decision of our life.',
                        name: 'Ana Prodan',
                        position: 'Resident of the Vorniceni complex',
                    },
                }
            },
        },

        complex: {
            description: 'Echipa noastră transformă visuri în realitate, construind nu doar clădiri, ci și povestea fiecărui vis realizat. Alegeți să vă încredințați echipei noastre pentru a modela împreună experiențe de neuitat.',
            photos: {
                pretitle: 'Beneficiile unei terase',
                title: 'Admiră apusurile și răsăriturile cu o cafeluță',
                text: 'Începe-ți ziua cu o priveliște uluitoare, admirând apusurile și răsăriturile în timp ce savurezi o cafeluță caldă. Experimentează liniștea și frumusețea naturii, bucurându-te de momente de contemplare și energizare. Fiecare înghițitură devine o călătorie într-un spațiu al liniștii, înconjurat de frumusețea și seninătatea peisajului.'
            },
            map: {
                pretitle: 'Infrastructure',
                title: 'Discover your home surroundings',
                pharmacy: 'Pharmacy',
                shop: 'Shop',
                restaurant: 'Restaurant',
                playground: 'Playground',
            },
            progress: {
                pretitle: 'Progresul construcției',
                title: 'Vezi cum s-a dezvoltat complexul de-a lungul timpului',
                text: 'Descoperă evoluția uimitoare a complexului nostru de-a lungul timpului. De la primele temelii până la arhitectura impresionantă și facilitățile moderne, fiecare etapă reflectă angajamentul nostru pentru excelență și inovație. Priviți înapoi la călătoria noastră și bucurați-vă de transformarea continuă a acestui spațiu într-un mediu vibrant și contemporan.'
            }
        },

        contacts: {
            pretitle: 'Our contacts',
            title: 'Sales office',
            address: 'Chisinau Municipality, 15 Alecu Russo Street, office 52',
            workingHours: 'Monday to Friday from 9.00 - 18.00',
        },

        success: {
            title: 'Your data has been successfully submitted!',
            description: 'An Estate Invest agent will contact you shortly.'
        },

        contactModal: {
            title: 'Complete the fields',
            firstName: 'First name*',
            lastName: 'Last name*',
            phoneNumber: 'Phone number*',
            email: 'Email address (optional)',
            button: 'Send',
            iAgreeTo: 'I agree to',
            termsAndConditions: 'Terms and Conditions',
            chooseComplex: 'Complex',
        },

        noData: 'No data available',

        seo: {
            index: {
                title: 'Estate Invest Company - Real estate developer in Moldova',
                description: 'Estate Invest Company is a real estate construction and development company based in Chișinău, known as one of the most pragmatic general contractors in Moldova.',
            },
            contact: {
                title: 'Contact us',
                description: 'Contact Estate Invest Company for any questions or requests. We are here to help you with any information you need.',
            },
            about: {
                title: 'About us - Estate Invest Company',
                description: 'Estate Invest Company was founded in 2015 with a clear goal: to become a leader in the field of real estate development in the Republic of Moldova.',
            },
            success: {
                title: 'Your data has been successfully submitted!',
                description: 'Your data has been successfully submitted! An Estate Invest agent will contact you shortly.',
            },
            projects: {
                title: 'Projects - Estate Invest Company',
                description: 'Discover the real estate complexes developed by Estate Invest Company. Choose the perfect apartment for you.',
            }
        },

        plans: {
            label: 'Available floor plans',
            title: 'Study the available floor plans and choose the right one for you',
            description: 'Discover the available floor plans and choose the perfect apartment for you. Each floor plan is designed to provide comfort, functionality, and optimal space, reflecting your needs and preferences. Choose the floor plan that best represents you and start turning your dream into reality.'
        }
    }
})