<script setup lang="ts">
interface Props {
  showSelectedName?: boolean
  dropdownDirection?: 'up' | 'down'
  matchTriggerWidth?: boolean
}

withDefaults(defineProps<Props>(), {
  showSelectedName: false,
  dropdownDirection: 'down',
  matchTriggerWidth: false
})

const { locale, locales, setLocale } = useI18n()

const availableLocales = computed(() => {
  return locales.value.filter(i => i.code !== locale.value).map((l) => ({
    name: l.name,
    code: l.code,
    icon: `flag_${l.code}`
  }))
})

const currentLocale = computed(() => {
  return {
    ...locales.value.find(i => i.code === locale.value),
    icon: `flag_${locale.value}`
  }
})

const active = ref(false)
const toggler = ref<HTMLElement|null>()

onClickOutside(toggler, () => {
  active.value = false
})
</script>

<template>
  <div
    ref="toggler"
    class="lng-toggler"
    :class="{ active }"
  >
    <div class="lng-toggler__trigger" :class="{ 'lng-toggler__trigger--with-name': showSelectedName }" @click="active = !active">
      <SvgIcon :name="currentLocale.icon" class="lng-toggler__current-icon" />
      <span v-if="showSelectedName" class="lng-toggler__current-name">{{ currentLocale.name }}</span>
      <SvgIcon name="arrow_down" class="lng-toggler__arrow" />
    </div>
    <div
      class="lng-toggler__options"
      :class="[
        `lng-toggler__options--${dropdownDirection}`,
        {
          'lng-toggler__options--match-trigger-width': matchTriggerWidth
        }
      ]"
    >
      <template
        v-for="option in availableLocales"
        :key="option.code"
      >
        <div
          v-if="option.code !== locale"
          class="lng-toggler__option"
          @click.prevent.stop="setLocale(option.code)"
        >
          <SvgIcon :name="option.icon" />
          <span>{{ option.name }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/scss/components/language-toggler';
</style>