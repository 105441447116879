<script setup lang="ts">
import { useIsBodyScrolled } from '~/composables/useIsBodyScrolled'
import useModalsStore from '~/stores/modals'

const i18n = useI18n()

const modalsStore = useModalsStore()

const navLinks = computed(() => [
  { to: '/', text: i18n.t('nav.home') },
  { to: '/about', text: i18n.t('nav.about') },
  { to: '/projects', text: i18n.t('nav.projects') },
  { to: '/contacts', text: i18n.t('nav.contacts') },
])

const isBodyScrolled = useIsBodyScrolled()
</script>

<template>
  <header
    class="header"
    :class="{ 'body-scrolled': isBodyScrolled }"
  >
    <div class="container">
      <div class="header__content">
        <NuxtLinkLocale class="header__logo" to="/">
          <img src="/img/logo-white.png" class="logo-white" alt="Estate Invest Company" />
          <img src="/img/logo.png" class="logo-color" alt="Estate Invest Company" />
        </NuxtLinkLocale>
        <nav class="header__nav">
          <NuxtLinkLocale
            v-for="link in navLinks"
            :key="link.to"
            :to="link.to"
            class="header__nav-item"
          >
            {{ link.text }}
          </NuxtLinkLocale>
        </nav>
        <div class="header__language-switcher">
          <LanguageToggler />
        </div>
        <button
          class="btn btn--primary btn--medium header__cta-button"
          @click.prevent="modalsStore.open('contact')"
        >
          {{ $t('requestConsultation') }}
        </button>
        <button
          class="header__mobile-nav-toggler hamburger hamburger--slider"
          :class="{ 'is-active': modalsStore.active.includes('mobile-nav') }"
          type="button"
          @click="modalsStore.toggle('mobile-nav')"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <LazyMobileNav />
  </header>
</template>

<style scoped lang="scss">
@use '@/assets/scss/components/header';
</style>