<script setup lang="ts">
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import 'swiper/css/effect-cards'
import 'swiper/css/effect-fade'

import useModalsStore from '~/stores/modals'

const modalsStore = useModalsStore()
const i18n = useI18n()

useHead({
  htmlAttrs: {
    lang: i18n.locale.value
  }
})
</script>

<template>
  <div class="_app">
    <Body
      :class="{
        'modal-opened': modalsStore.isSomeActive,
        'mobile-nav-opened': modalsStore.active.includes('mobile-nav')
      }"
    />
    <SvgIcons />
    <TheHeader />
    <main class="main">
      <NuxtPage />
    </main>
    <TheFooter />

    <ContactModal />
  </div>
</template>

<style lang="scss">
@use '@/assets/scss/main';
</style>