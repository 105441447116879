<script setup lang="ts">
import useModalsStore from '~/stores/modals'

const i18n = useI18n()

const modalsStore = useModalsStore()

const config = useRuntimeConfig()
const { data: complexes, pending: complexesPending } = await useFetch<any>(`${config.public.apiUrl}/complexes`)

const localePath = useLocalePath()

const aboutLinks = computed(() => [
  { to: localePath('/about'), text: i18n.t('footer.links.about.items.company') },
  { to: localePath('/projects?status_id=1'), text: i18n.t('footer.links.about.items.finishedProjects') },
  { to: localePath('/projects?status_id=2'), text: i18n.t('footer.links.about.items.inProgressProjects') },
  { to: localePath('/contacts'), text: i18n.t('footer.links.about.items.contacts') },
])
</script>

<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__content">
        <div class="footer__columns">
          <div class="footer__column">
            <NuxtLinkLocale to="/" class="footer__logo">
              <img src="/img/logo.png" alt="Estate Invest Company" />
            </NuxtLinkLocale>
            <div class="footer__cta">
              <div class="footer__cta-title">{{ $t('footer.cta.title') }}</div>
              <button class="footer__cta-button btn btn--secondary btn--medium" @click.prevent="modalsStore.open('contact')">
                {{ $t('footer.cta.buttonText') }}
              </button>
            </div>
            <div class="footer__about">{{ $t('footer.cta.text') }}</div>
          </div>
          <div v-if="!complexesPending" class="footer__column">
            <div class="footer__column-name">{{ $t('footer.links.complexes.title') }}</div>
            <NuxtLinkLocale
              v-for="item in complexes.body"
              :key="item.id"
              :to="item.redirect_url"
              class="footer__link"
            >
              <span>{{ item.complex_name }}</span>
            </NuxtLinkLocale>
          </div>
          <div class="footer__column">
            <div class="footer__column-name">{{ $t('footer.links.about.title') }}</div>
            <NuxtLinkLocale v-for="link in aboutLinks" :key="link.to" :to="link.to" class="footer__link">
              <span>{{ link.text }}</span>
            </NuxtLinkLocale>
          </div>
          <div class="footer__column">
            <div class="footer__column-name">{{ $t('footer.links.office.title') }}</div>
            <a href="https://maps.app.goo.gl/DDQuhvXGvoaaMYaDA" target="_blank" class="footer__contact">
              <SvgIcon name="map_pointer" />
              <span>Mun.Chisinau str. A. Russo 15 of 52</span>
            </a>
            <a href="tel:37362199999" class="footer__contact">
              <SvgIcon name="phone" />
              <span>+(373) 62 199999</span>
            </a>
            <a href="tel:37361299999" class="footer__contact">
              <SvgIcon name="phone" />
              <span>+(373) 61 299999</span>
            </a>
            <a href="tel:37322199999" class="footer__contact">
              <SvgIcon name="phone" />
              <span>+(373) 22 199999</span>
            </a>
            <a href="mailto:vinzari@estateinvest.md" class="footer__contact">
              <SvgIcon name="mail" />
              <span>vinzari@estateinvest.md</span>
            </a>

            <div class="footer__socials">
              <h4 class="footer__socials-title">{{ $t('footer.socialsTitle') }}</h4>
              <div class="footer__socials-items">
                <a href="https://www.instagram.com/estate_invest_company?igsh=Y3FyMDY4bmV4NDBk" target="_blank">
                  <SvgIcon name="instagram" />
                </a>
                <a href="https://www.facebook.com/EstateInvestCompany" target="_blank">
                  <SvgIcon name="facebook" />
                </a>
                <a href="https://youtube.com/@estateinvestcompany?feature=shared" target="_blank">
                  <SvgIcon name="youtube" />
                </a>
                <a href="https://www.tiktok.com/@estateinvestcompany?_t=8lNTPz5vQjF&_r=1" target="_blank">
                  <SvgIcon name="tiktok" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="footer__bottom">
          <div class="footer__copyright">{{ $t('footer.copyright') }}</div>
          <div class="footer__policies">
            <NuxtLinkLocale to="/terms">{{ $t('footer.policies.cookies') }}</NuxtLinkLocale>
            <NuxtLinkLocale to="/policy">{{ $t('footer.policies.privacy') }}</NuxtLinkLocale>
          </div>
          <NuxtLinkLocale class="footer__dev" external target="_blank" to="//midavco.com">
            {{ $t('footer.developedBy') }}
            <img src="/img/devs.svg" alt />
          </NuxtLinkLocale>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
@use '@/assets/scss/components/footer';
</style>