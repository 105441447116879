export default defineI18nLocale(async () => {
  return {
    nav: {
      home: 'Домой',
      about: 'О компании',
      projects: 'Проекты',
      contacts: 'Контакты',
    },
    requestConsultation: 'Запросить консультацию',
    welcome: {
      pretitle: 'Change Your Life',
      title: 'Откройте для себя комплекс\n<strong>{complex}</strong>',
      buttonText: 'Обзор ЖК',
      instagramText: 'Следи за нами в Instagram',
    },
    experience: {
      pretitle: 'Ты всё ещё думаешь?',
      title: 'Накопленный опыт говорит за нас',
      years: 'лет',
      buildings: 'зданий',
      clients: 'клиентов',
      experience: 'опыта',
      built: 'построено',
      developed: 'возведено',
      remainedSatisfied: 'остались довольными',
    },
    all: 'Все',
    actual: 'На этапе строительства',
    finished: 'Завершён',
    infoTitles: {
      blocks: 'Комплексы',
      apartments: 'Квартиры',
      undergroundParking: 'Подземные парковки',
      landParking: 'Наземные парковки',
      available: 'Еще доступны',
      availableApartments: 'квартир',
    },
    functions: {
      pretitle: 'Как это работает',
      title: 'На 4 шага ближе к твоему дому',
      step1: 'Запроси консультацию прямо на сайте',
      step2: 'Представитель Estate свяжется с тобой',
      step3: 'Приходи к нам в офис, чтобы узнать, какая недвижимость дома урна сейчас и график оплаты',
      step4: 'Выбери идеальную квартиру для тебя',
    },
    advantages: {
      pretitle: 'Чтобы облегчить тебе выбор',
      title: 'Наши партнеры помогут тебе с…',
      item1: {
        title: 'Ремонт квартиры под ключ',
        text: 'Полное предоставление услуг по ремонту и обустройству, включая материалы, работу и контроль, для обеспечения завершения проекта без участия владельца.'
      },
      item2: {
        title: 'Индивидуальный дизайн',
        text: 'Персонализация пространства в соответствии со вкусами, потребностями и индивидуальностью владельца, посредством создания уникальной и комфортной среды, адаптированной специально для него или его семьи.'
      },
      item3: {
        title: 'Умные решения для дома',
        text: 'Передовые технологии, такие как системы автоматизации освещения, встроенная безопасность, голосовое управление устройствами, умные термостаты и датчики мониторинга энергопотребления, — все для создания комфортной, безопасной и энергоэффективной среды.'
      },
      item4: {
        title: 'Финансовые учреждения и банки',
        text: 'Если тебе не удалось приобрести квартиру в рассрочку напрямую от застройщика, то сразу после ввода в эксплуатацию мы поможем тебе определиться с подходящим предложением для оформления Ипотеки или покупки квартиры по правительственной программе Prima Casă.'
      },
      buttonText: 'Воспользуйтесь предложением сейчас'
    },
    footer: {
      cta: {
        title: 'Ты уже сделал свой выбор?',
        buttonText: 'Связаться с нами',
        text: 'Estate Invest Company - компания по строительству и развитию недвижимости с штаб-квартирой в Кишиневе, являющаяся одним из самых прагматичных генеральных предпринимателей в Молдове.',
      },
      links: {
        complexes: {
          title: 'Жилые комплексы'
        },
        about: {
          title: 'Ссылки',
          items: {
            company: 'О компании',
            finishedProjects: 'Завершённые проекты',
            inProgressProjects: 'На этапе строительства',
            contacts: 'Контакты',
          }
        },
        office: {
          title: 'Офис продаж',
        }
      },
      socialsTitle: 'Подпишись на нас в социальных сетях',
      copyright: '© 2012 Компания Estate Invest - Все права защищены',
      policies: {
        cookies: 'Политика использования файлов cookie',
        privacy: 'Политика конфиденциальности',
      },
      developedBy: 'разработано командой',
    },

    about: {
      title: 'О нас',
      text: `
        Компания Estate Invest была основана в 2015 году с четкой целью: стать лидером в сфере девелопмента недвижимости в Республике Молдова. Мы стремимся обеспечить инновационный и высококачественный подход к строительству домов и коммерческих помещений, уделяя особое внимание оригинальной архитектуре, а также комфорту и удовлетворенности клиентов.
        <br><br>
        Первые сданные проекты, такие как Park House и Estate Tower, стали эталонами отрасли благодаря превосходному качеству строительства и вниманию к деталям. Эти проекты помогли нам укрепить репутацию надежного застройщика, получившего  уважение в обществе.
      `,
      weOffer: {
        title: 'Мы лучшие, потому что предлагаем',
        items: {
          item1: {
            title: 'Гарантируем превосходное качество',
            text: 'Мы уделяем приоритетное внимание качеству строительства и к деталям, чтобы повысить комфорт каждого жильца, выбирающего Estate Invest Company.',
          },
          item2: {
            title: 'Строения с характером',
            text: 'Мы стремимся улучшить качество жизни сообщества посредством ценных проектов. Мы фокусируемся на идентификации и развитии проектов в лучших и наиболее перспективных локациях.',
          },
          item3: {
            title: 'Инновации и совершенство',
            text: 'Мы интегрируем новейшие технологии и инновации во все наши проекты. Энергоэффективность, долговечность и интеграция умных систем – это лишь несколько важных точек в развитии каждого объекта.',
          },
          item4: {
            title: 'Обязательства перед клиентами',
            text: 'Каждый проект реализуется с самоотдачей и вниманием к потребностям клиентов, будь то конечные покупатели, молодые семьи или инвесторы.',
          },
        }
      },
      team: {
        title: 'Большая команда строит большие мечты',
        text: 'Имея команду из более чем 200 талантливых и преданных своему делу сотрудников, компания реализует сложные проекты, имеющие национальный резонанс. Управленческая команда, возглавляемая опытными профессионалами в сфере недвижимости, поддерживает качество и видение застройщика и гарантирует успешную реализацию всех проектов. Специалисты на строительных площадках несут ответственность за выполнение планов строительства, управление ресурсами и обеспечение качества работ. Благодаря упорному труду и техническому опыту они воплощают идеи в реальность. А команда продаж благодаря своим коммуникативным навыкам и обширным знаниям создает и поддерживает прочные отношения с клиентами и партнерами, способствуя успеху и росту бизнеса.',
      },
      testimonials: {
        pretitle: 'Мнения клиентов имеют значение',
        title: 'Опыт наших клиентов',
        items: {
          item1: {
            text: 'Мой опыт работы с Estate Invest был исключительным! Проект Estate Tower — замечательное достижение в сфере недвижимости. Это была моя первая инвестиция, а недавно я приобрел еще 2 квартиры в Olimp Towers',
            name: 'Ион Русу',
            position: 'Инвестор Estate Tower',
          },
          item2: {
            text: 'Качество их построек замечательное. Комплекс, расположенный рядом с парком Долина Роз – лучшее решение в нашей жизни.',
            name: 'Анна Продан',
            position: 'Жительница комплекса Vorniceni',
          },
        }
      },
    },

    complex: {
      description: 'Echipa noastră transformă visuri în realitate, construind nu doar clădiri, ci și povestea fiecărui vis realizat. Alegeți să vă încredințați echipei noastre pentru a modela împreună experiențe de neuitat.',
      photos: {
        pretitle: 'Beneficiile unei terase',
        title: 'Admiră apusurile și răsăriturile cu o cafeluță',
        text: 'Începe-ți ziua cu o priveliște uluitoare, admirând apusurile și răsăriturile în timp ce savurezi o cafeluță caldă. Experimentează liniștea și frumusețea naturii, bucurându-te de momente de contemplare și energizare. Fiecare înghițitură devine o călătorie într-un spațiu al liniștii, înconjurat de frumusețea și seninătatea peisajului.'
      },
      map: {
        pretitle: 'Инфраструктура',
        title: 'Откройте для себя окрестности вашего дома',
        pharmacy: 'Аптека',
        shop: 'Магазин',
        restaurant: 'Ресторан',
        playground: 'Игровая площадка',
      },
      progress: {
        pretitle: 'Progresul construcției',
        title: 'Vezi cum s-a dezvoltat complexul de-a lungul timpului',
        text: 'Descoperă evoluția uimitoare a complexului nostru de-a lungul timpului. De la primele temelii până la arhitectura impresionantă și facilitățile moderne, fiecare etapă reflectă angajamentul nostru pentru excelență și inovație. Priviți înapoi la călătoria noastră și bucurați-vă de transformarea continuă a acestui spațiu într-un mediu vibrant și contemporan.'
      }
    },

    contacts: {
      pretitle: 'Наши контакты',
      title: 'Офис продаж',
      address: 'Муниципий Кишинев, улица Алеку Руссо 15, офис 52',
      workingHours: 'Понедельник - Пятница с 9.00 - 18.00',
    },

    success: {
      title: 'Ваши данные успешно переданы!',
      description: 'В течение короткого времени с вами свяжется агент компании Estate Invest.',
    },

    contactModal: {
      title: 'Заполни поля',
      firstName: 'Имя*',
      lastName: 'Фамилия*',
      phoneNumber: 'Номер телефона*',
      email: 'Электронный адрес (опционально)',
      button: 'Отправить',
      iAgreeTo: 'Я согласен с',
      termsAndConditions: 'Условиями использования',
      chooseComplex: 'Комплекс',
    },

    noData: 'Нет доступных данных',

    seo: {
      index: {
        title: 'Estate Invest - компания по строительству и развитию недвижимости',
        description: 'Estate Invest - компания по строительству и развитию недвижимости с штаб-квартирой в Кишиневе, являющаяся одним из самых прагматичных генеральных предпринимателей в Молдове.',
      },
      contact: {
        title: 'Контакты Estate Invest',
        description: 'Офис продаж Estate Invest находится по адресу: Муниципий Кишинев, улица Алеку Руссо 15, офис 52. Работаем с понедельника по пятницу с 9.00 - 18.00.',
      },
      about: {
        title: 'О компании Estate Invest',
        description: 'Компания',
      },
      success: {
        title: 'Ваши данные успешно переданы!',
        description: 'В течение короткого времени с вами свяжется агент компании Estate Invest.',
      },
      projects: {
        title: 'Проекты Estate Invest',
        description: 'Estate Invest - компания по строительству и развитию недвижимости с штаб-квартирой в Кишиневе, являющаяся одним из самых прагматичных генеральных предпринимателей в Молдове.',
      }
    },

    plans: {
      label: 'Доступные планировки',
      title: 'Изучите доступные планировки и выберите подходящую для вас планировку',
      description: 'Откройте для себя доступные планировки и выберите идеальную квартиру для себя. Каждая планировка спроектирована для обеспечения комфорта, функциональности и оптимального пространства, отражая ваши потребности и предпочтения. Выберите планировку, которая лучше всего вас представляет, и начните воплощать свою мечту в реальность.'
    }
  }
})