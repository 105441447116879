<script setup lang="ts">
import useModalsStore from '~/stores/modals'

const modalsStore = useModalsStore()

const emit = defineEmits(['cancel', 'transition:end'])

interface Props {
  name: string
  type?: 'drawer' | 'modal'
}

const props = withDefaults(defineProps<Props>(), {
  type: 'modal',
})

const active = computed(() => modalsStore.active?.includes(props.name))

function close() {
  modalsStore.close(props.name)
  emit('cancel')
}
</script>

<template>
  <div
    class="modal"
    :class="{ active, 'modal--drawer': props.type === 'drawer'}"
    @transitionend.self="emit('transition:end', active)"
    @click.self="close"
  >
    <div
      class="modal__content"
      :class="{ 'modal__content--drawer': props.type === 'drawer' }"
      @click.self="close"
    >
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/scss/components/modal';
</style>